import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t, t2 } from '@/@core/libs/i18n/utils'
import { $themeConfig } from '@themeConfig'

export default function tablePaymentList() {
  // Use toast
  const toast = useToast()

  const refPaymentListTable = ref(null)
  const lineOa = computed(() => store.state.lineapi.lineOa)

  const msgList = {
    errorfetch: t2('Error fetching {module} list', { module: t('TitleBooking.Payment') }),
    id: `${t('ID')} » ${t('FormPayment.Type')} » ${t('FormPayment.Event')}`,
    payment: `${t('FormPayment.Payment')}`,
    actions: t('Actions'),
  }

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true, label: msgList.id },
    { key: 'payment', sortable: true, label: msgList.payment },
    { key: 'actions', label: msgList.actions },
  ]

  const optionAll = [{
    name: t('All'),
    value: '0',
  }]

  const perPage = ref(10)
  const totalPayments = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [2, 5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const locale = ref('th')

  const productTypeFilter = ref('0')
  const productCategoryFilter = ref('0')
  const productFilter = ref('0')
  const eventCodeFilter = ref('')
  const nameFilter = ref('')
  const eventTypeFilter = ref([])
  const paymentStatusFilter = ref([])
  const paymentDateFilter = ref([])
  const productTypeOptions = ref(optionAll)
  const productCategoryOptions = ref(optionAll)
  const productOptions = ref(optionAll)

  const dataMeta = computed(() => {
    const localItemsCount = refPaymentListTable.value ? refPaymentListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPayments.value,
    }
  })

  const refetchData = () => {
    refPaymentListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, locale, productFilter, paymentDateFilter, eventCodeFilter, nameFilter, eventTypeFilter, paymentStatusFilter], () => {
    refetchData()
  })

  const getListPayment = (ctx, callback) => {
    const { id } = JSON.parse(lineOa.value)
    const params = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      language: locale.value,
      eventCode: eventCodeFilter.value,
      name: nameFilter.value,
      eventType: eventTypeFilter.value,
      paymentStatus: paymentStatusFilter.value,
      productType: productTypeFilter.value,
      productCategory: productCategoryFilter.value,
      product: productFilter.value,
      paymentDate: paymentDateFilter.value,
      lineOaId: id,
    }
    store
      .dispatch('store-payment/getListPayment', params)
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalPayments.value = total
        localStorage.setItem(`${$themeConfig.app.session}-filter-payment-list`, JSON.stringify(params))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgList.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  const getProductTypeOptions = () => {
    const params = {
      language: locale.value,
    }
    store
      .dispatch('store-product-type/getProductTypeOptions', params)
      .then(response => {
        productTypeOptions.value = [
          ...optionAll,
          ...response.data,
        ]
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgList.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  const getProductCategoryOptions = () => {
    const params = {
      language: locale.value,
      productType: productTypeFilter.value,
    }
    store
      .dispatch('store-product-category/getProductCategoryOptions', params)
      .then(response => {
        productCategoryOptions.value = [
          ...optionAll,
          ...response.data,
        ]
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgList.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  const getProductOptions = () => {
    const params = {
      language: locale.value,
      productType: productTypeFilter.value,
      productCategory: productCategoryFilter.value,
    }
    store
      .dispatch('store-product/getProductOptions', params)
      .then(response => {
        productOptions.value = [
          ...optionAll,
          ...response.data,
        ]
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgList.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  watch([productTypeFilter], () => {
    if (productTypeFilter.value !== '' && productTypeFilter.value !== '0') {
      productCategoryFilter.value = '0'
      productFilter.value = '0'
      getProductCategoryOptions()
    } else {
      productCategoryOptions.value = optionAll
      productOptions.value = optionAll
      productCategoryFilter.value = '0'
      productFilter.value = '0'
    }
    refetchData()
  })
  watch([productCategoryFilter], () => {
    if (productCategoryFilter.value !== '' && productCategoryFilter.value !== '0') {
      productFilter.value = '0'
      getProductOptions()
    } else {
      productOptions.value = optionAll
      productFilter.value = '0'
    }
    refetchData()
  })

  const isStatusVariant = status => {
    if (status === 'wait_payment') return 'info'
    if (status === 'canceled') return 'danger'
    if (status === 'paymented') return 'success'
    return 'secondary'
  }

  const textFirstUpper = text => text.slice(0, 1).toUpperCase() + text.slice(1, text.length)

  const numberFormat = value => parseFloat(value).toFixed(2)

  const numberFormatter = (value, decimal) => {
    if (value === '') return ''
    return parseFloat(parseFloat(value).toFixed(decimal)).toLocaleString(
      'en-US',
      {
        minimumFractionDigits: decimal,
        useGrouping: true,
      },
    )
  }

  const eventTypeOptions = [
    {
      label: t('EventType.all'),
      value: 'all',
    },
    {
      label: t('EventType.booking'),
      value: 'booking',
    },
    {
      label: t('EventType.renting'),
      value: 'renting',
    },
  ]

  const eventTypeVariant = type => {
    if (type === 'booking') return 'info'
    if (type === 'renting') return 'success'
    return 'secondary'
  }

  const paymentStatusOptions = [
    {
      label: t('StatusMessage.all'),
      value: 'all',
    },
    {
      label: t('StatusMessage.wait_payment'),
      value: 'wait_payment',
    },
    {
      label: t('StatusMessage.canceled'),
      value: 'canceled',
    },
    {
      label: t('StatusMessage.paymented'),
      value: 'paymented',
    },
  ]

  return {
    getListPayment,
    tableColumns,
    perPage,
    currentPage,
    totalPayments,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    locale,
    refPaymentListTable,

    refetchData,
    isStatusVariant,
    textFirstUpper,
    numberFormat,
    numberFormatter,

    eventCodeFilter,
    nameFilter,
    eventTypeFilter,
    paymentStatusFilter,

    eventTypeOptions,
    eventTypeVariant,

    productTypeFilter,
    productCategoryFilter,
    productFilter,
    paymentDateFilter,

    getProductTypeOptions,
    productTypeOptions,
    getProductCategoryOptions,
    productCategoryOptions,
    getProductOptions,
    productOptions,

    paymentStatusOptions,
  }
}
