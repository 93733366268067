import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultRenting(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_default_renting', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListRenting(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_list_renting', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getRenting(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_renting', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRenting(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/add_renting', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editRenting(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/edit_renting', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRenting(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/delete_renting', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListRentingSelect(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_list_renting_select', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getCalendarRenting(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_calendar_renting', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getTimeRenting(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_time_renting', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getSummaryRenting(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_summary_renting', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
