<template>
  <b-modal
    id="renting-select"
    :visible="showRentingSelectModal"
    :title="`${$t('Select')} ${$t('FormRenting.Renting')}`"
    footer-class="d-flex justify-content-between"
    size="xl"
    centered
    hide-footer
    no-close-on-backdrop
    @change="(val) => $emit('update:show-renting-select-modal', val)"
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          {{ `${$t('Select')} ${$t('FormRenting.Renting')}` }}
        </h5>
        <div class="modal-actions">
          <feather-icon
            icon="MinusIcon"
            class="cursor-pointer"
            @click="(busy === false) ? $emit('update:show-renting-select-modal', false) : false"
          />
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click="(busy === false) ? onDiscard() : false"
          />
        </div>
      </template>

      <b-card
        class="p-0 m-0"
        style="margin-top: 0.8rem !important;"
      >
        <b-card-title style="margin-bottom: 0rem !important;">
          <div>
            <small
              v-if="rentingData.name !== undefined && rentingData.name !== ''"
            >{{ `${$t('Select')} ${$t('FormRenting.Renting')}` }}</small>
            <b-alert
              v-if="rentingData.name !== undefined && rentingData.name !== ''"
              show
              variant="success"
              style="margin-top: 0.2rem;"
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="CheckCircleIcon"
                />
                <span class="ml-25">{{ rentingData.name }}</span>
              </div>
            </b-alert>
          </div>
        </b-card-title>

        <div style="margin: 0rem 0rem 0rem 0rem;">
          <b-row>
            <b-col
              cols="12"
              sm="4"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">
                {{ $t('Showing {from} to {to} of {of} entries',
                      {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                }}
              </span>
            </b-col>
            <b-col
              cols="12"
              sm="12"
              md="2"
            >
              <b-form-group
                :label="`${$t('FormRenting.PaymentStatus')}`"
                label-for="payment-status"
              >
                <v-select
                  id="payment-status"
                  v-model="rentingFilter.payment_status"
                  :options="paymentStatusOptions"
                  class="w-100"
                  :clearable="false"
                  :reduce="val => val.value"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              sm="12"
              md="2"
            >
              <b-form-group
                :label="`${$t('FormRenting.ApproveStatus')}`"
                label-for="approve-status"
              >
                <v-select
                  id="approve-status"
                  v-model="rentingFilter.approve_status"
                  :options="approveStatusOptions"
                  class="w-100"
                  :clearable="false"
                  :reduce="val => val.value"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              sm="12"
              md="2"
            >
              <b-form-group
                :label="`${$t('FormRenting.Status')}`"
                label-for="status"
              >
                <v-select
                  id="status"
                  v-model="rentingFilter.status"
                  :options="statusOptions"
                  class="w-100"
                  :clearable="false"
                  :reduce="val => val.value"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              sm="12"
              md="2"
            >
              <b-form-group
                :label="`${$t('Search')}`"
                label-for="search"
              >
                <b-form-input
                  id="search"
                  v-model="rentingFilter.q"
                  class="d-inline-block m-0"
                  :placeholder="`${$t('Search')}...`"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <div
          class="pb-75 pl-75 pr-75 pt-75"
          style="border: 1px solid #cccccc; background-color: #eeeeee; border-radius: 0.5rem;"
        >
          <b-row>
            <b-col
              v-for="tableCol in tableColumns"
              :key="tableCol.key"
              role="columnheader"
              :cols="tableCol.col"
            >
              <div>
                {{ tableCol.label }}
              </div>
            </b-col>
          </b-row>
        </div>
        <VuePerfectScrollbar
          class="scroll-area"
          :settings="settings"
          @ps-scroll-y="scrollHandle"
        >
          <b-row
            v-for="item in rentingList"
            :key="item.id"
            class="mt-1 mb-1 mr-1"
          >
            <b-col cols="3">
              <!-- Code/ID -->
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('ID') }} »
                    </span>
                    <span style="font-size: 14px;">
                      <b-badge
                        pill
                        :variant="(skin === 'dark')? `light-info` : `light-info` "
                        class="text-capitalize"
                      >
                        {{ item.id }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('FormRenting.RentingCode') }} »
                    </span>
                    <span style="font-size: 18px;">
                      <b-badge
                        pill
                        :variant="`${item.status_color}`"
                      >
                        {{ item.renting_code }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('FormRenting.PaymentStatus') }} »
                    </span>
                    <span style="font-size: 16px;">
                      <b-badge
                        pill
                        :variant="`light-${item.payment_status_color}`"
                      >
                        {{ $t(`StatusMessage.${item.payment_status}`) }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('FormRenting.ApproveStatus') }} »
                    </span>
                    <span style="font-size: 16px;">
                      <b-badge
                        pill
                        :variant="`light-${item.approve_status_color}`"
                      >
                        {{ $t(`StatusMessage.${item.approve_status}`) }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('FormRenting.Status') }} »
                    </span>
                    <span style="font-size: 16px;">
                      <b-badge
                        pill
                        :variant="`light-${item.status_color}`"
                      >
                        {{ $t(`StatusMessage.${item.status}`) }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </b-col>

            <b-col cols="3">
              <!-- Product -->
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('FormRenting.ProductName') }} »
                    </span>
                    <span style="font-size: 14px;">
                      {{ item.name }}
                    </span>
                  </b-list-group-item>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      <b-badge
                        pill
                        variant="light-success"
                      >
                        {{ item.product_type.name }}
                      </b-badge> »
                    </span>
                    <span style="font-size: 14px;">
                      {{ item.category_name }}
                    </span>
                  </b-list-group-item>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="font-size: 14px;">
                      {{ item.department_name }}
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </b-col>

            <b-col cols="4">
              <!-- Renting -->
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('Date') }} »
                    </span>
                    <span style="font-size: 14px;">
                      <feather-icon icon="CalendarIcon" />
                      {{ `${item.start_date.name} - ${item.end_date.name}` }}
                    </span>
                  </b-list-group-item>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('FormRenting.Amount') }} »
                    </span>
                    <span style="font-size: 16px;">
                      {{ `${item.amount_show} ${$t('Baht')}` }}
                    </span>
                  </b-list-group-item>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('FormRenting.Renting By') }} »
                    </span>
                    <span style="font-size: 16px;">
                      <feather-icon icon="UserIcon" />
                      {{ item.user_name }}
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </b-col>

            <b-col cols="2">

              <div
                style="margin-top: 0.3rem;"
              >
                <b-button
                  v-if="checkSelect(item)"
                  size="sm"
                  variant="primary"
                  style="margin-left: 0rem;"
                  @click.stop="onSelect(item)"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="14"
                    style="margin-right: 0.4rem;"
                  />
                  <span class="align-middle">{{ $t('Select') }}</span>
                </b-button>

                <b-button
                  v-else
                  size="sm"
                  variant="outline-success"
                  style="margin-left: 0rem;"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="14"
                    style="margin-right: 0.4rem;"
                  />
                  <span class="align-middle">{{ $t('Selected') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </VuePerfectScrollbar>
      </b-card>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCard,
  BCardTitle,
  BRow,
  BCol,
  BAlert,
  BListGroup,
  BListGroupItem,
  BBadge,
  BFormInput,
  BFormGroup,

  BOverlay,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t } from '@/@core/libs/i18n/utils'
import vSelect from 'vue-select'
import storeRenting from './storeRenting'

export default {
  components: {
    BButton,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BAlert,
    BListGroup,
    BListGroupItem,
    BBadge,
    BFormInput,
    BOverlay,
    BFormGroup,

    VuePerfectScrollbar,
    vSelect,
  },
  model: {
    prop: 'showRentingSelectModal',
    event: 'update:show-renting-select-modal',
  },
  props: {
    showRentingSelectModal: {
      type: Boolean,
      required: true,
    },
    rentingData: {
      type: Object,
      default: () => {},
    },
    rentingFilter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showOverlay: false,
      busy: false,
      skin: store.state.appConfig.layout.skin,
      settings: {
        maxScrollbarLength: 60,
      },
      perPage: 100,
      totalItem: 0,
      currentPage: 1,
      sortBy: 'renting_code',
      isSortDirDesc: true,
      locale: 'th',
      q: '',
      payment_status: '',
      approve_status: '',
      status: '',
      rentingList: [],
      tableColumns: [],
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.rentingList.length
      return {
        from: (localItemsCount ? 1 : 0),
        to: localItemsCount,
        of: this.totalItem,
      }
    },
  },
  watch: {
    'rentingFilter.q': {
      handler() {
        this.refetchData()
      },
    },
    'rentingFilter.payment_status': {
      handler() {
        this.refetchData()
      },
    },
    'rentingFilter.approve_status': {
      handler() {
        this.refetchData()
      },
    },
    'rentingFilter.status': {
      handler() {
        this.refetchData()
      },
    },
    showRentingSelectModal(value) {
      if (value === true) {
        this.rentingList = []
        this.currentPage = 1
        this.getListRentingSelect()
      }
    },
  },
  mounted() {
    const msgList = {
      code: `${this.$i18n.t('FormRenting.RentingCode')}`,
      name: `${this.$i18n.t('FormRenting.ProductName')}`,
      renting: `${this.$i18n.t('FormRenting.Renting')}`,
      actions: this.$i18n.t('Actions'),
    }
    this.tableColumns = [
      {
        key: 'code', sortable: true, label: msgList.code, col: 3,
      },
      {
        key: 'name', sortable: true, label: msgList.name, col: 3,
      },
      {
        key: 'renting', sortable: true, label: msgList.renting, col: 4,
      },
      { key: 'actions', label: msgList.actions, col: 2 },
    ]
  },
  methods: {
    getListRentingSelect() {
      this.showOverlay = true
      const params = {
        rentingId: this.rentingData.id,
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        language: this.locale,
        q: this.rentingFilter.q,
        payment_status: this.rentingFilter.payment_status,
        approve_status: this.rentingFilter.approve_status,
        status: this.rentingFilter.status,
      }
      store
        .dispatch('store-renting/getListRentingSelect', params)
        .then(response => {
          const { list, total } = response.data
          if (this.currentPage === 1) {
            // console.log('first')
            this.rentingList = list
          } else {
            // append
            // console.log('append')
            list.forEach(ll => {
              this.rentingList.push(ll)
            })
            // console.log(this.rentingList)
          }
          this.totalItem = total
          this.showOverlay = false
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    refetchData() {
      this.rentingList = []
      this.currentPage = 1
      this.getListRentingSelect()
    },
    scrollHandle(event) {
      // console.log(event)

      // console.log(event.target.scrollTop)
      // console.log(event.target.clientHeight)
      // console.log(event.target.scrollHeight)
      if (event.target.scrollTop + event.target.clientHeight >= (event.target.scrollHeight - 2)) {
        // console.log('last scroll')
        if (this.showOverlay === false) {
          const totalListItem = this.rentingList.length
          // console.log(totalListItem)
          if (this.totalItem > totalListItem) {
            this.currentPage += 1
            // console.log('load more')
            this.getListRentingSelect()
          }
        }
      }
    },
    onSelect(item) {
      this.busy = true
      this.$emit('update-renting-select', { select: item, renting: this.rentingData })
      this.$emit('update:show-renting-select-modal', false)
      this.busy = false
    },
    checkSelect(item) {
      if (parseInt(item.id, 10) === parseInt(this.rentingData.id, 10)) {
        return false
      }
      return true
    },
  },
  setup() {
    if (!store.hasModule('store-renting')) store.registerModule('store-renting', storeRenting)
    onUnmounted(() => {
      if (store.hasModule('store-renting')) store.unregisterModule('store-renting')
    })
    const paymentStatusOptions = [
      {
        label: t('All'),
        value: '',
      },
      {
        label: t('StatusMessage.wait_payment'),
        value: 'wait_payment',
      },
      {
        label: t('StatusMessage.canceled'),
        value: 'canceled',
      },
      {
        label: t('StatusMessage.paymented'),
        value: 'paymented',
      },
    ]
    const approveStatusOptions = [
      {
        label: t('All'),
        value: '',
      },
      {
        label: t('StatusMessage.wait_approve'),
        value: 'wait_approve',
      },
      {
        label: t('StatusMessage.approved'),
        value: 'approved',
      },
      {
        label: t('StatusMessage.disapproved'),
        value: 'disapproved',
      },
    ]
    const statusOptions = [
      {
        label: t('All'),
        value: '',
      },
      {
        label: t('StatusMessage.wait_payment'),
        value: 'wait_payment',
      },
      {
        label: t('StatusMessage.wait_approve'),
        value: 'wait_approve',
      },
      {
        label: t('StatusMessage.approved'),
        value: 'approved',
      },
      {
        label: t('StatusMessage.disapproved'),
        value: 'disapproved',
      },
      {
        label: t('StatusMessage.canceled'),
        value: 'canceled',
      },
      {
        label: t('StatusMessage.complete'),
        value: 'complete',
      },
    ]
    return { paymentStatusOptions, approveStatusOptions, statusOptions }
  },
}
</script>

<style>
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 50vh;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
