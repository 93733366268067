import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultPayment(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_default_payment', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListPayment(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_list_payment', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPayment(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_payment', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPayment(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/add_payment', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editPayment(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/edit_payment', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePayment(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/delete_payment', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImagePaymentImage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/booking/upload_image_payment_image/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteImagePaymentImage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/delete_image_payment_image', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkPayment(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/check_payment', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
