<template>
  <b-modal
    id="payment-form"
    :visible="shallShowPaymentFormModal"
    :title="`${$t('Form')} ${$t('TitleBooking.Payment')}`"
    footer-class="d-flex justify-content-between"
    size="xl"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:shall-show-payment-form-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ `${$t(typePaymentForm)} ${$t('TitleBooking.Payment')}` }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="(busy === false) ? $emit('update:shall-show-payment-form-modal', false) : false"
        />
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          v-if="(typePaymentForm !== 'View')"
          ref="submit"
          variant="primary"
          right
          :disabled="busy"
          @click="onSubmit"
        >
          {{ (typePaymentForm === 'Add' || typePaymentForm === 'Edit' || typePaymentForm === 'Inform' || typePaymentForm === 'Process') ? $t('Save Data') : $t('Delete Data') }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <div>
        <b-form
          ref="refForm"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div>
            <b-card style="margin-bottom: 0.6rem;">
              <div>
                <b-row>
                  <!-- Field: Event Type -->
                  <b-col cols="3">
                    <b-form-group
                      v-if="(typePaymentForm === 'Add')"
                      :label="$t('FormPayment.EventType')"
                      label-for="payment-event-type"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('FormPayment.EventType')"
                        rules="required"
                      >
                        <b-form-radio
                          v-model="paymentData.event_type"
                          name="payment-event-type"
                          value="booking"
                          class="cursor-pointer mt-50"
                          :class="`custom-control-${eventTypeVariant(paymentData.event_type)}`"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t('EventType.Booking') }}</span>
                        </b-form-radio>
                        <b-form-radio
                          v-model="paymentData.event_type"
                          name="payment-event-type"
                          value="renting"
                          class="cursor-pointer mt-50"
                          :class="`custom-control-${eventTypeVariant(paymentData.event_type)}`"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t('EventType.Renting') }}</span>
                        </b-form-radio>
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else-if="typePaymentForm === 'View' || typePaymentForm === 'Edit' || typePaymentForm === 'Inform' || typePaymentForm === 'Process'"
                      :label="$t('FormPayment.EventType')"
                      label-for="payment-event-type"
                    >
                      <b-badge
                        pill
                        :variant="`light-${eventTypeVariant(paymentData.event_type)}`"
                        class="px-1 py-50 mt-0"
                        style="font-size: 16px;"
                      >
                        {{ $t(`EventType.${textFirstUpper(paymentData.event_type)}`) }}
                      </b-badge>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Event -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      v-if="(typePaymentForm === 'Add')"
                      :label="$t('FormPayment.Event')"
                      label-for="payment-event"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('FormPayment.Event')"
                        rules="required"
                      >
                        <b-form-input
                          v-model="paymentData.event.value"
                          style="display: none;"
                          :state="getValidationState(validationContext)"
                        />
                        <b-input-group
                          class="m-0"
                          @click.prevent="selectEvent"
                        >
                          <b-input-group-prepend
                            is-text
                            style="cursor: pointer;"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              size="12"
                            />
                          </b-input-group-prepend>
                          <b-form-input
                            id="payment-event"
                            ref="refEvent"
                            v-model="paymentData.event.name"
                            :readonly="true"
                            style="background: #f8f8f8 !important; cursor: pointer;"
                            @click="selectEvent"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('FormPayment.EventCode')"
                      label-for="payment-event"
                    >
                      <span class="form-info-box">{{ paymentData.event_code }}</span>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Product Type -->
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-group
                      v-if="(typePaymentForm === 'Add' || typePaymentForm === 'Edit' || typePaymentForm === 'Inform' || typePaymentForm === 'Process')"
                      :label="$t('FormPayment.ProductType')"
                      label-for="payment-event-product-type"
                    >
                      <span
                        class="form-info-box"
                        style="min-height: 38px;"
                      >{{ paymentData.product_type }}</span>
                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('FormPayment.ProductType')"
                      label-for="payment-event-product-type"
                    >
                      <span class="form-info-box">{{ paymentData.product_type }}</span>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <!-- Field: Name -->
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-group
                      v-if="(typePaymentForm === 'Add' || typePaymentForm === 'Edit')"
                      :label="$t('FormPayment.ProductName')"
                      label-for="payment-event-name"
                    >
                      <span
                        class="form-info-box"
                        style="min-height: 38px;"
                      >{{ paymentData.name }}</span>
                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('FormPayment.ProductName')"
                      label-for="payment-event-name"
                    >
                      <span class="form-info-box">{{ paymentData.name }}</span>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Category -->
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-group
                      v-if="(typePaymentForm === 'Add' || typePaymentForm === 'Edit')"
                      :label="$t('FormPayment.Category')"
                      label-for="payment-event-category-name"
                    >
                      <span
                        class="form-info-box"
                        style="min-height: 38px;"
                      >{{ paymentData.category_name }}</span>
                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('FormPayment.Category')"
                      label-for="payment-event-category-name"
                    >
                      <span class="form-info-box">{{ paymentData.category_name }}</span>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <!-- Field: Payment Date -->
                  <b-col cols="3">
                    <b-form-group
                      v-if="(typePaymentForm === 'Add' || typePaymentForm === 'Edit' || typePaymentForm === 'Inform' || typePaymentForm === 'Process')"
                      :label="$t('FormPayment.PaymentDate')"
                      label-for="payment-payment-date"
                    >
                      <b-input-group
                        class="m-0"
                      >
                        <el-date-picker
                          v-model="paymentData.payment_datetime"
                          type="datetime"
                          :placeholder="$t('Select Date')"
                          format="dd MMMM yyyy HH:mm:ss"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          class="w-100"
                        />
                      </b-input-group>
                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('FormPayment.PaymentDate')"
                      label-for="payment-payment-date"
                    >
                      <span
                        class="form-info-box"
                      >
                        <feather-icon
                          icon="CalendarIcon"
                          class="mr-50"
                        />
                        {{ (paymentData.payment_date !== undefined && paymentData.payment_date.name !== undefined)? paymentData.payment_date.name : '' }}
                        <feather-icon
                          icon="ClockIcon"
                          class="ml-1 mr-50"
                        />
                        {{ (paymentData.payment_date !== undefined && paymentData.payment_time.name !== undefined)? paymentData.payment_time.name : '' }}
                      </span>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Payment Type -->
                  <b-col cols="3">
                    <b-form-group
                      v-if="(typePaymentForm === 'Add' || typePaymentForm === 'Edit' || typePaymentForm === 'Inform' || typePaymentForm === 'Process')"
                      :label="`${$t('FormPayment.PaymentType')}`"
                      label-for="payment-payment-type-code"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('FormPayment.PaymentType')"
                        rules="required"
                      >
                        <v-select
                          v-model="paymentData.payment_type_code"
                          :options="paymentData.payment_type_options"
                          :reduce="val => val.value"
                          :clearable="false"
                          input-id="payment-payment-type-code"
                          :state="getValidationState(validationContext)"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('FormPayment.PaymentType')"
                      label-for="payment-payment-type"
                    >
                      <span class="form-info-box">
                        {{ paymentData.payment_type.name }}
                      </span>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Payment Amount -->
                  <b-col cols="2">
                    <b-form-group
                      v-if="(typePaymentForm === 'Add' || typePaymentForm === 'Edit' || typePaymentForm === 'Inform' || typePaymentForm === 'Process')"
                      :label="`${$t('FormPayment.PaymentAmount')} (${$t('Baht')})`"
                      label-for="payment-payment-amount"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('FormPayment.PaymentAmount')"
                        rules="required"
                      >
                        <b-form-input
                          id="payment-payment-amount"
                          v-model="paymentData.payment_amount"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                          @keypress="IsNumber"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('FormPayment.PaymentAmount')"
                      label-for="payment-payment-amount"
                    >
                      <span class="form-info-box">{{ paymentData.payment_amount }}</span>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Discount -->
                  <b-col cols="2">
                    <b-form-group
                      v-if="(typePaymentForm === 'Add' || typePaymentForm === 'Edit' || typePaymentForm === 'Inform' || typePaymentForm === 'Process')"
                      :label="`${$t('FormPayment.Discount')} (${$t('Baht')})`"
                      label-for="payment-discount"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('FormPayment.Discount')"
                        rules="required"
                      >
                        <b-form-input
                          id="payment-discount"
                          v-model="paymentData.discount"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                          @keypress="IsNumber"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('FormPayment.Discount')"
                      label-for="payment-discount"
                    >
                      <span class="form-info-box">{{ paymentData.discount }}</span>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Amount -->
                  <b-col cols="2">
                    <b-form-group
                      v-if="(typePaymentForm === 'Add' || typePaymentForm === 'Edit' || typePaymentForm === 'Inform' || typePaymentForm === 'Process')"
                      :label="`${$t('FormPayment.Amount')} (${$t('Baht')})`"
                      label-for="payment-amount"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('FormPayment.Amount')"
                        rules="required"
                      >
                        <b-form-input
                          id="payment-amount"
                          v-model="paymentData.amount"
                          :state="getValidationState(validationContext)"
                          :readonly="true"
                          :disabled="busy"
                          @keypress="IsNumber"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('FormPayment.Amount')"
                      label-for="payment-amount"
                    >
                      <span class="form-info-box">{{ paymentData.amount }}</span>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <!-- Field: Payment Status -->
                  <b-col cols="3">
                    <b-form-group
                      v-if="(typePaymentForm === 'Add' || typePaymentForm === 'Edit' || typePaymentForm === 'Inform' || typePaymentForm === 'Process')"
                      :label="$t('FormPayment.PaymentStatus')"
                      label-for="payment-payment-status"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('FormPayment.PaymentStatus')"
                        rules="required"
                      >
                        <b-form-radio
                          v-model="paymentData.payment_status"
                          name="payment-payment-status"
                          value="paymented"
                          class="custom-control-success cursor-pointer mt-50"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t('StatusMessage.paymented') }}</span>
                        </b-form-radio>
                        <b-form-radio
                          v-model="paymentData.payment_status"
                          name="payment-payment-status"
                          value="canceled"
                          class="custom-control-danger cursor-pointer mt-50"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t('StatusMessage.canceled') }}</span>
                        </b-form-radio>
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('FormPayment.PaymentStatus')"
                      label-for="payment-payment-status"
                    >
                      <span class="form-info-box">
                        <b-badge
                          pill
                          :variant="`light-${isStatusVariant(paymentData.payment_status)}`"
                          class="text-capitalize"
                        >
                          {{ $t(`StatusMessage.${paymentData.payment_status}`) }}
                        </b-badge>
                      </span>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Image -->
                  <b-col cols="5">
                    <b-form-group
                      v-if="(typePaymentForm === 'Add' || typePaymentForm === 'Edit' || typePaymentForm === 'Inform' || typePaymentForm === 'Process')"
                      :label="`${$t('FormPayment.Image')}`"
                      label-for="payment-image"
                    >
                      <van-uploader
                        v-model="paymentFile.image"
                        multiple
                        accept="image/jpeg,image/png,image/heif"
                        :max-size="maxSizeImage"
                        :before-read="onBeforeReadImage"
                        :after-read="onAfterReadImage"
                        :preview-options="imageOptions"
                        :before-delete="onBeforeDeleteImage"
                        @oversize="onOversizeImage"
                      >
                        <template #preview-cover="{ file }">
                          <div class="preview-cover van-ellipsis">
                            {{ (file)? file.name : '' }}
                          </div>
                        </template>
                      </van-uploader>
                    </b-form-group>
                    <b-form-group
                      v-else
                      :label="$t('FormPayment.Image')"
                      label-for="payment-image"
                    >
                      <van-uploader
                        v-model="paymentData.image"
                        multiple
                        accept="image/jpeg,image/png,image/heif"
                        :preview-options="previewOptions"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: Payment Period -->
                  <b-col
                    v-if="paymentData.event_type === 'renting'"
                    cols="2"
                  >
                    <b-form-group
                      v-if="(typePaymentForm === 'Add' || typePaymentForm === 'Edit' || typePaymentForm === 'Inform' || typePaymentForm === 'Process')"
                      :label="`${$t('FormPayment.PaymentPeriod')}`"
                      label-for="payment-payment-period"
                    >
                      <b-form-input
                        id="payment-payment-period"
                        v-model="paymentData.payment_period"
                        :readonly="true"
                        :disabled="busy"
                      />
                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('FormPayment.Amount')"
                      label-for="payment-payment-period"
                    >
                      <span class="form-info-box">{{ paymentData.payment_period }}</span>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Payment Term -->
                  <b-col
                    v-if="paymentData.event_type === 'renting'"
                    cols="2"
                  >
                    <b-form-group
                      v-if="(typePaymentForm === 'Add' || typePaymentForm === 'Edit' || typePaymentForm === 'Inform' || typePaymentForm === 'Process')"
                      :label="`${$t('FormPayment.PaymentTerm')}`"
                      label-for="payment-payment-term"
                    >
                      <b-form-input
                        id="payment-payment-term"
                        v-model="paymentData.payment_term"
                        :readonly="true"
                        :disabled="busy"
                      />
                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('FormPayment.Amount')"
                      label-for="payment-payment-term"
                    >
                      <span class="form-info-box">{{ paymentData.payment_term }}</span>
                    </b-form-group>
                  </b-col>
                </b-row>

              </div>
            </b-card>
          </div>

        </b-form>
      </div>

    </validation-observer>

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>

    <!-- Booking Select -->
    <booking-select
      v-model="showBookingSelectModal"
      :title="`${$t('Select')} ${$t('FormBooking.Booking')}`"
      :booking-data="bookingData"
      :booking-filter="bookingFilter"
      @update-booking-select="updateBookingSelect"
      @discard-booking-select="discardBookingSelect"
    />
    <!-- Renting Select -->
    <renting-select
      v-model="showRentingSelectModal"
      :title="`${$t('Select')} ${$t('FormRenting.Renting')}`"
      :renting-data="rentingData"
      :renting-filter="rentingFilter"
      @update-renting-select="updateRentingSelect"
      @discard-renting-select="discardRentingSelect"
    />
  </b-modal>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BOverlay,
  BProgress,
  BCardText,
  BInputGroup,
  BInputGroupPrepend,
  BFormRadio,
  BBadge,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  Toast,
  Dialog,
} from 'vant'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import {
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import BookingSelect from '@/views/booking/booking/BookingSelect.vue'
import RentingSelect from '@/views/booking/renting/RentingSelect.vue'
import moment from 'moment'
import { $themeConfig } from '@themeConfig'
import tablePaymentList from './tablePaymentList'
import storePayment from './storePayment'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BProgress,
    BCardText,
    BInputGroup,
    BInputGroupPrepend,
    BFormRadio,
    BBadge,

    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
    vSelect,

    BookingSelect,
    RentingSelect,
  },
  model: {
    prop: 'shallShowPaymentFormModal',
    event: 'update:shall-show-payment-form-modal',
  },
  props: {
    shallShowPaymentFormModal: {
      type: Boolean,
      required: true,
    },
    typePaymentForm: {
      type: String,
      default: 'Add',
    },
    paymentData: {
      type: Object,
      default: () => {},
    },
    paymentFile: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem(`${$themeConfig.app.commonSession}-user-data`)),
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      skin: store.state.appConfig.layout.skin,

      // event (booking, renting)
      showBookingSelectModal: false,
      bookingData: {},
      bookingFilter: {
        payment_status: '',
        approve_status: '',
        status: '',
        q: '',
      },
      showRentingSelectModal: false,
      rentingData: {},
      rentingFilter: {
        payment_status: '',
        approve_status: '',
        status: '',
        q: '',
      },

      // image
      maxSizeImage: 5000 * 1024,
      imageOptions: {
        closeable: true,
        deletable: true,
      },
      previewOptions: {
        closeable: true,
        deletable: false,
      },
    }
  },
  computed: {
    lineOa() {
      const value = store.state.lineapi.lineOa
      if (value === '' || value === undefined) {
        return ''
      }
      return JSON.parse(value)
    },
    lineOaId() {
      const value = this.lineOa.id
      if (value === '' || value === undefined) {
        return ''
      }
      return value
    },
  },
  watch: {
    'paymentData.event_type': {
      handler(value) {
        if (this.typePaymentForm === 'Add' || this.typePaymentForm === 'Inform' || this.typePaymentForm === 'Process') {
          const now = moment().format('YYYY-MM-DD HH:mm:ss')
          this.paymentData.payment_datetime = now

          if (this.typePaymentForm === 'Add') {
            this.paymentData.event = {
              name: '',
              value: '',
            }
            this.paymentData.name = ''
            this.paymentData.product_type = ''
            this.paymentData.product_id = ''
            this.paymentData.type_code = ''
            this.paymentData.category_name = ''
            this.paymentData.department_name = ''
            this.paymentData.payment_amount = 0
            this.paymentData.payment_period = 0
            this.paymentData.payment_term = 0
          }
          if (value === 'booking') {
            this.bookingData = {}
          } else if (value === 'renting') {
            this.rentingData = {}
          }
        }
      },
    },
    shallShowPaymentFormModal(value) {
      if (value === true) {
        if (this.paymentData.payment_datetime === '') {
          const now = moment().format('YYYY-MM-DD HH:mm:ss')
          this.paymentData.payment_datetime = now
        }
      }
    },
    'paymentData.payment_amount': {
      handler() {
        if (this.paymentData.payment_amount !== '' && this.paymentData.discount !== '') {
          const amount = parseFloat(this.paymentData.payment_amount) - parseFloat(this.paymentData.discount)
          this.paymentData.amount = amount
        } else {
          this.paymentData.amount = 0
        }
      },
    },
    'paymentData.discount': {
      handler() {
        if (this.paymentData.payment_amount !== '' && this.paymentData.discount !== '') {
          const amount = parseFloat(this.paymentData.payment_amount) - parseFloat(this.paymentData.discount)
          this.paymentData.amount = amount
        } else {
          this.paymentData.amount = 0
        }
      },
    },
  },
  methods: {
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      if (this.typePaymentForm === 'Add' || this.typePaymentForm === 'Inform' || this.typePaymentForm === 'Process') {
        this.paymentData.line_oa_id = this.lineOaId
        this.paymentData.payment_user_id = this.userData.id
        this.paymentData.verify_user_id = this.userData.id
        this.paymentData.create_user_id = this.userData.id
        store.dispatch('store-payment-form/addPayment', this.paymentData)
          .then(response => {
            let uploadFound = 0
            const uploadImage = this.paymentFile.image
            uploadImage.forEach(item => {
              if (item.file !== undefined) {
                uploadFound += 1
              }
            })

            if (uploadFound > 0) {
              this.uploadImagePaymentImage(response.data.id)
            } else {
              // not upload image file
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })

              if (this.typePaymentForm === 'Add') {
                this.$swal({
                  icon: 'success',
                  title: this.$i18n.t('Added'),
                  text: this.$i18n.t('Your data has been added'),
                  timer: 3000,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
              this.busy = false
              this.processing = false

              this.$emit('payment-form-update')
              this.$emit('update:shall-show-payment-form-modal', false)
            }
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typePaymentForm === 'Edit') {
        store.dispatch('store-payment-form/editPayment', { id: this.paymentData.id, data: this.paymentData })
          .then(response => {
            let uploadFound = 0
            const uploadImage = this.paymentFile.image
            uploadImage.forEach(item => {
              if (item.file !== undefined) {
                uploadFound += 1
              }
            })

            if (uploadFound > 0) {
              this.uploadImagePaymentImage(response.data.id)
            } else {
              // not upload image file
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.busy = false
              this.processing = false

              this.$emit('payment-form-update')
              this.$emit('update:shall-show-payment-form-modal', false)
            }
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      }
    },
    onDiscard() {
      this.$emit('discard-payment-form')
      this.$emit('update:shall-show-payment-form-modal', false)
    },
    selectEvent() {
      if (this.paymentData.event_type === 'booking') {
        if (this.paymentData.event.value === '') {
          this.bookingData = {}
        } else {
          this.bookingData = {}
          this.bookingData.id = this.paymentData.event.value
          this.bookingData.code = this.paymentData.event_code
          this.bookingData.name = this.paymentData.name
          this.bookingData.type = this.paymentData.event_type
        }
        this.bookingFilter = {
          payment_status: 'wait_payment',
          approve_status: '',
          status: '',
          search: '',
        }
        this.showBookingSelectModal = true
      } else if (this.paymentData.event_type === 'renting') {
        if (this.paymentData.event.value === '') {
          this.rentingData = {}
        } else {
          this.rentingData = {}
          this.rentingData.id = this.paymentData.event.value
          this.rentingData.code = this.paymentData.event_code
          this.rentingData.name = this.paymentData.name
          this.rentingData.type = this.paymentData.event_type
        }
        this.rentingFilter = {
          payment_status: 'wait_payment',
          approve_status: '',
          status: '',
          q: '',
        }
        this.showRentingSelectModal = true
      }
    },
    checkPayment(data, type) {
      this.showOverlay = true
      const eventCode = (type === 'booking') ? data.select.booking_code : data.select.renting_code
      store
        .dispatch('store-payment-form/checkPayment', {
          event_code: eventCode,
          language: this.$i18n.locale,
        })
        .then(response => {
          const payment = response.data
          if (payment.is_payment !== undefined && payment.is_payment === true) {
            // found
            this.$swal({
              title: this.$i18n.t('Are you sure?'),
              text: this.$i18n.t('Found a previous payment. Continue to payment'),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: this.$i18n.t('Confirm'),
              cancelButtonText: this.$i18n.t('Cancel'),
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value === true) {
                // ok
                this.showOverlay = false
                if (type === 'booking') {
                  this.updateBookingSelectData(data)
                } else if (type === 'renting') {
                  this.updateRentingSelectData(data)
                }
              }
            })
          } else {
            // not found
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.showOverlay = false
            if (type === 'booking') {
              this.updateBookingSelectData(data)
            } else if (type === 'renting') {
              this.updateRentingSelectData(data)
            }
          }
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = true
        })
    },
    updateBookingSelectData(data) {
      this.$nextTick(() => {
        this.paymentData.event.value = data.select.id
        this.paymentData.event.name = data.select.booking_code
        this.paymentData.event_id = data.select.id
        this.paymentData.event_code = data.select.booking_code
        this.paymentData.name = data.select.name
        this.paymentData.product_type = data.select.product_type.name
        this.paymentData.product_id = data.select.product_id
        this.paymentData.type_code = data.select.type_code
        this.paymentData.category_name = data.select.category_name
        this.paymentData.department_name = data.select.department_name
        this.paymentData.payment_amount = data.select.fee_amount

        this.bookingData.id = data.select.id
        this.bookingData.code = data.select.booking_code
        this.bookingData.name = data.select.name
        this.bookingData.type = this.paymentData.event_type

        this.$refs.refForm.focus()
      })
    },
    updateBookingSelect(data) {
      this.checkPayment(data, 'booking')
    },
    discardBookingSelect() {
    },
    updateRentingSelectData(data) {
      this.$nextTick(() => {
        this.paymentData.event.value = data.select.id
        this.paymentData.event.name = data.select.renting_code
        this.paymentData.event_id = data.select.id
        this.paymentData.event_code = data.select.renting_code
        this.paymentData.name = data.select.name
        this.paymentData.product_type = data.select.product_type.name
        this.paymentData.product_id = data.select.product_id
        this.paymentData.type_code = data.select.type_code
        this.paymentData.category_name = data.select.category_name
        this.paymentData.department_name = data.select.department_name
        this.paymentData.payment_amount = data.select.amount
        this.paymentData.payment_period = data.select.payment_period
        this.paymentData.payment_term = data.select.payment_term

        this.rentingData.id = data.select.id
        this.rentingData.code = data.select.renting_code
        this.rentingData.name = data.select.name
        this.rentingData.type = this.paymentData.event_type

        this.$refs.refForm.focus()
      })
    },
    updateRentingSelect(data) {
      this.checkPayment(data, 'renting')
    },
    discardRentingSelect() {
    },
    IsNumber(event) {
      if (!/\d/.test(event.key)) return event.preventDefault()
      return null
    },
    // eslint-disable-next-line no-unused-vars
    onOversizeImage(file) {
      Toast(this.$i18n.t('ValidateMessage.File size cannot exceed', { size: '5Mb' }))
    },
    onBeforeReadImage(file) {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/heif') {
        Toast({
          message: this.$i18n.t('Please upload an image in jpg/png/heif file'),
          icon: 'warning-o',
          position: 'middle',
          duration: 4000,
        })
        return false
      }
      return true
    },
    onAfterReadImage(file) {
      if (this.paymentData.id !== '' && this.paymentData.id !== undefined && this.paymentData.id > 0) {
        Dialog.confirm({
          title: this.$i18n.t('Confirmation'),
          message: this.$i18n.t('Do you want to upload image?'),
          cancelButtonText: this.$i18n.t('Cancel'),
          confirmButtonText: this.$i18n.t('Upload'),
          confirmButtonColor: 'blue',
        })
          .then(() => {
            // on confirm
            file.status = 'uploading'
            file.message = 'Uploading...'

            const fData = new FormData()
            fData.append('file0', file.file)

            const params = {
              id: this.paymentData.id,
              data: fData,
            }
            store.dispatch('store-payment-form/uploadImagePaymentImage', params)
              // eslint-disable-next-line no-unused-vars
              .then(response => {
                const { result } = response.data
                if (result.length > 0) {
                  file.url = result[0].url
                  file.payment_id = result[0].payment_id
                  file.file_name = result[0].file_name
                  delete file.file
                  delete file.content
                }
                file.status = 'uploaded'
                delete file.message
                Toast({
                  message: this.$i18n.t('Process on successfully'),
                  icon: 'success',
                  position: 'middle',
                  duration: 4000,
                })
              })
              .catch(() => {
                file.status = 'failed'
                file.message = 'Failed'
              })
          })
          .catch(() => {
            // on cancel
            file.status = 'failed'
            file.message = 'Wait Save'
          })
      }
    },
    onBeforeDeleteImage(file) {
      return new Promise((resolve, reject) => {
        if (file.status !== undefined && file.status === 'failed') {
          resolve()
        } else if (this.paymentData.id > 0) {
          Dialog.confirm({
            title: this.$i18n.t('Confirmation'),
            message: this.$i18n.t('Do you want to delete?'),
            cancelButtonText: this.$i18n.t('Cancel'),
            confirmButtonText: this.$i18n.t('Delete'),
            confirmButtonColor: 'red',
          })
            .then(() => {
              // on confirm
              file.status = 'uploading'
              file.message = 'Deleting...'
              resolve(true)
              const params = {
                payment_id: this.paymentData.id,
                file_name: file.file.name,
              }
              store.dispatch('store-payment-form/deleteImagePaymentImage', params)
                // eslint-disable-next-line no-unused-vars
                .then(response => {
                  file.status = ''
                  file.message = ''
                  Toast({
                    message: this.$i18n.t('Process on successfully'),
                    icon: 'success',
                    position: 'middle',
                    duration: 4000,
                  })
                  resolve(true)
                })
                .catch(error => {
                  file.status = 'failed'
                  file.message = 'Failed'
                  reject(error)
                })
            })
            .catch(error => {
            // on cancel
              reject(error)
            })
        } else {
          resolve()
        }
      })
    },
    uploadImagePaymentImage(id) {
      if (id !== '') {
        this.showOverlay = true

        const uploadImage = this.paymentFile.image
        const fData = new FormData()
        uploadImage.forEach((item, index) => {
          fData.append(`file${index}`, item.file)
        })

        const params = {
          id,
          data: fData,
        }

        store.dispatch('store-payment-form/uploadImagePaymentImage', params)
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.showOverlay = true
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })

            if (this.typePaymentForm === 'Add') {
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Added'),
                text: this.$i18n.t('Your data has been added'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }

            this.busy = false
            this.processing = false

            this.$emit('payment-form-update')
            this.$emit('update:shall-show-payment-form-modal', false)
          })
          .catch(error => {
            this.showOverlay = false
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else {
        const okMsg = this.$i18n.t('Process on error')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: okMsg,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        },
        {
          position: 'bottom-right',
        })
        this.busy = false
        this.processing = false
      }
    },
  },
  setup() {
    if (!store.hasModule('store-payment-form')) store.registerModule('store-payment-form', storePayment)
    onUnmounted(() => {
      if (store.hasModule('store-payment-form')) store.unregisterModule('store-payment-form')
    })
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    const {
      textFirstUpper,
      isStatusVariant,
      eventTypeVariant,
    } = tablePaymentList()

    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      textFirstUpper,
      isStatusVariant,
      eventTypeVariant,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
.jsoneditor-poweredBy {
  display: none !important;
}
.jsoneditor {
  border: thin solid #d8d6de !important;
}
.jsoneditor-menu{
  background-color: #aaaaaa !important;
  border-bottom: 1px solid #aaaaaa !important;
}
</style>

<style lang="scss">
.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}

.van-uploader__upload {
  width: 180px;
  height: 180px;
}
.van-uploader__preview-image {
  width: 180px;
  height: 180px;
}
</style>
